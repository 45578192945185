import 'sweetalert/dist/sweetalert.css'
import 'ng-dialog/css/ngDialog.min.css'
import 'ng-dialog/css/ngDialog-theme-default.min.css'
import 'bootstrap'
import 'angular-datatables/dist/css/angular-datatables.min.css'

export { globals } from './js/config'

import './pdApp'
import './js/run/run'
import './js/filters'
import './js/services'
import './js/controllers'

