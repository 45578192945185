
      import API from "!../../node_modules/style-loader/dist/runtime/injectStylesIntoLinkTag.js";
      import insertFn from "!../../node_modules/style-loader/dist/runtime/insertBySelector.js";
      import content from "!!../../node_modules/file-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/sass-loader/dist/cjs.js!./style.scss";
      

var options = {};


      options.insert = insertFn.bind(null, "head");
    

var update = API(content, options);



export default {}