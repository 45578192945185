export { pdApp } from "../../pdApp";
export { globals } from "../../index";

import "./costing/bills.controller";
import "./login/change-password.controller";
import "./clients/client-details.controller";
import "./clients/client-list.controller";
import "./clients/client-new.controller";
import "./commissions";
import "./costing/costing.controller";
import "./deadlines";
import "./exchange-rate";
import "./inventory/inventory-adjustments.controller";
import "./inventory/inventory-cross.controller";
import "./inventory/inventory-cross-new.controller";
import "./inventory/inventory-in.controller";
import "./inventory/inventory-movements.controller";
import "./inventory/inventory-report.controller";
import "./login/login.controller";
import "./molding-details";
import "./movements";
import "./order/order-details.controller";
import "./order/order-list.controller";
import "./payments";
import "./personal-info";
import "./product-details";
import "./products-catalog";
import "./products-new";
import "./products";
import "./order/quote-list.controller";
import "./order/quote-new.controller";
import "./statistics";
import "./users/user-details.controller";
import "./users/user-list.controller";
import "./users/user-new.controller";
import "./inventory/warehouse/warehouse-details.controller";
import "./inventory/warehouse/warehouses.controller";
import "./resources/resources";
import "./reports/reports.controller"
import "./order/installation-calendar"
import "./order/provider-list.controller"
import "./order/provider-order-details.controller"
