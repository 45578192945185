require("datatables.net/js/jquery.dataTables");

require("./dist/angular-datatables");
require("./dist/plugins/bootstrap/angular-datatables.bootstrap");
require("./dist/plugins/colreorder/angular-datatables.colreorder");
require("./dist/plugins/columnfilter/angular-datatables.columnfilter");
require("./dist/plugins/light-columnfilter/angular-datatables.light-columnfilter");
require("./dist/plugins/colvis/angular-datatables.colvis");
require("./dist/plugins/fixedcolumns/angular-datatables.fixedcolumns");
require("./dist/plugins/fixedheader/angular-datatables.fixedheader");
require("./dist/plugins/scroller/angular-datatables.scroller");
require("./dist/plugins/tabletools/angular-datatables.tabletools");
require("./dist/plugins/buttons/angular-datatables.buttons");
require("./dist/plugins/select/angular-datatables.select");
module.exports = "datatables";
