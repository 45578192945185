import { pdApp } from './index';

pdApp.filter('catalogStatus', function () {
	return function (type) {
		switch (type) {
			case 'REGISTERED': return 'Registrado';
			case 'UPLOADED': return 'Cargado';
			case 'IMPORTED': return 'Exitoso';
			case 'EXPIRED': return 'Expirado';
			case 'BAD_FORMAT': return 'Formato incorrecto';
			case 'FAILED': return 'Fallido';
			default: return type;
		}
	};
});
